import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import styles from './VideoPlayer.module.scss';

function VideoPlayer(props) {
	const [showPlayButton, setShowPlayButton] = useState(props.isFirstScene);
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		setShowPlayButton(props.isFirstScene);
		setIsPlaying(!props.isFirstScene);
	}, [props.isFirstScene]);

	function playVideoFile() {
		setShowPlayButton(false);
		setIsPlaying(true);
	}

	return (
		<React.Fragment>
			{showPlayButton ? (
				<div className={styles.bgImageWrapper}>
					<figure className={styles.playButton}>
						<button onClick={() => playVideoFile()} name="play"></button>
					</figure>
					<img src={props.backgroundImage} className={styles.backgroundImage} alt="background" />
				</div>

			) : (
				<ReactPlayer
					width={'100%'}
					height={'auto'}
					url={props.videoUrl}
					className={showPlayButton ? styles.displayNone : styles.videoPlayer}
					playing={ isPlaying }
					controls={!showPlayButton}
					config={{ file: { attributes: { controlsList: 'nodownload' } } }}
					pip={false}
					playsinline={true}
				/>
			)}
		</React.Fragment>
	);
}

export default VideoPlayer;