import { SET_JSON_TEMPLATE_KEYS } from '../actions/jsonTemplateActions';

const initialState = {
	keys: [],
};

export default function setJsonTemplateKeysReducer(state = initialState, action) {
	switch (action.type) {
		case SET_JSON_TEMPLATE_KEYS:
			return { ...state, keys: action.payload };
		default:
			return state;
	}
}