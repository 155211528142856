
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import classNames from 'classnames';
import { ReactComponent as ChevronLeft } from '../../assets/icons/back.svg';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import Template from '../Template/Template';
import { fetchSendAnalytics } from '../../actions/scenesActions';
import { DEMO_PROJECT_ID, FALLBACK_PROJECT_ID } from '../../utils/constants';
import { getUTMObject } from '../../utils/getUTMObject';
import styles from './Widget.module.scss';

const HOST = process.env.REACT_APP_BE_URL;

// WebFont.load({
// 	google: {
// 		families: ['Work Sans:300,600', 'sans-serif']
// 	}
// });

function Widget({ embeddedProjectID, sendToEmail }) {
	const [scenes, setScenes] = useState([]);
	const [selectedProject, setSelectedProject] = useState({});
	const [currentScene, setCurrentScene] = useState();
	const [firstSceneId, setFirstSceneId] = useState('');
	const [sessionStarted, setSessionStarted] = useState();
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const [projectNotFound, setProjectNotFound] = useState(false);
	const [hasBackButton, setHasBackButton] = useState(false);
	const isMuted = useSelector((state) => state.muteButton.isMuted);

	const projectButtonBgColor = selectedProject?.general?.buttonBackgroundColor?.hex;
	let hasAudio = currentScene?.audioFileUrl;

	let completedTime = null;
	let exitScene = null;

	const history = useRef([]);

	// if it is a preview, the url contains '/project/<project-ID>'; get project-ID from there.
	// Otherwise, use the project-ID from the web page where the widget is embedded.
	// Also checks weather the request comes form embedded website or is same origin there was issue when embedded website had /project/<something> in their url.
	const pathname = window.location.pathname
	const isSameOrigin = window.location.origin !== process.env.REACT_APP_WIDGET_URL;
	const previewProjectId = (!isSameOrigin && pathname.startsWith('/project')) ? pathname.split('/')[2] : null;
	const projectID = previewProjectId ? previewProjectId : embeddedProjectID || FALLBACK_PROJECT_ID;

	const UTMObject = getUTMObject(window.location.search);

	useEffect(() => {
		const route = UTMObject.draft ? '/scenes/draft/' : '/scenes/';
		axios
			.get(HOST + route + projectID)
			.then((res) => {
				const project = res.data.project;
				// if this is an instance of a developer testing our demo, add the sendToEmail to the project.
				if (projectID === DEMO_PROJECT_ID && sendToEmail) project.sendToEmail = sendToEmail;
				setSelectedProject(project);
				setScenes(res.data.scenes);
				setProjectNotFound(project === null);
			})
			.catch((error) => {
				console.log('request get project failed with error: : ', error);
				setProjectNotFound(true);
			});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (history.current.length === 0) {
			setSessionStarted(new Date());
		}
	}, [history.current.length]);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		exitScene = currentScene;
		selectedProject?.analyticsActivated && sendSession();
	}, [currentScene]);

	useEffect(() => {
		// let breadcrumbsCopy = breadcrumbs;
		currentScene && breadcrumbs.push(currentScene);
		setBreadcrumbs(breadcrumbs);
		setHasBackButton(currentScene?.hasBackButton && firstSceneId !== currentScene?._id.toString());
	}, [breadcrumbs, currentScene, firstSceneId]);

	useEffect(() => {
		setFirstScene();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scenes]);

	// This is used only by Contact, Contact-Pro templates (and FreeText if it continues to an external URL)
	function getSessionCompletedTime() {
		completedTime = new Date();
		selectedProject?.analyticsActivated && sendSession();
	}

	function goBack() {
		let previous = findPreviousOfId();
		if (previous) setCurrentSceneById(previous);
	}

	function sendSession() {
		const projectId = selectedProject?._id;
		const host = window.location.origin;
		const sourceUrl = window.location.href;

		// let urlString = window.location.search;
		// let urlParams = new URLSearchParams(urlString);
		// let entries = urlParams.entries();
		// let urlEntries = {};

		// for (let entry of entries) {
		// 	let data = {
		// 		[entry[0]]: entry[1],
		// 	};
		// 	Object.assign(urlEntries, data);
		// }

		const session = {
			projectID: projectId,
			sessionStarted: sessionStarted,
			sessionCompleted: completedTime,
			exitScene: {
				id: exitScene?._id,
				title: exitScene?.title,
				question: exitScene?.question,
			},
			historySteps: getHistoryList().history,
			UTM: UTMObject,
			host: host,
			sourceUrl: sourceUrl,
		};
		fetchSendAnalytics(session).then();
	}

	const findSceneById = useCallback(() => {
		for (let scene of scenes) {
			if (scene.isFirstScene) {
				setFirstSceneId(scene._id);
				return scene;
			}
		}
	}, [scenes]);

	const setFirstScene = useCallback(() => {
		const firstScene = UTMObject.start_scene ?
			scenes?.find(scene => scene._id === UTMObject.start_scene) || scenes?.find(scene => scene.isFirstScene)
			: scenes?.find(scene => scene.isFirstScene);
		if (firstScene) {
			setCurrentScene(firstScene);
			setFirstSceneId(firstScene._id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scenes, findSceneById]);

	const setCurrentSceneById = (id, answerLabel = '') => {
		/* this first part is only for a FreeText template that continues to an external URL */
		if (id === 'FreeText External URL') {
			history.current.push({
				from: currentScene._id,
				to: 'external URL',
				fromScene: currentScene,
				fromSceneLabel: answerLabel,
				toScene: 'external URL',
				hasAudio: hasAudio?.length ? true : false,
				muted: isMuted
			});
		} else if (id) // for all templates...
			for (let scene of scenes) {
				if (scene._id.toString() === id.toString()) {
					setCurrentScene(scene);
					history.current.push({
						from: currentScene._id,
						to: scene._id,
						fromScene: currentScene,
						fromSceneLabel: answerLabel,
						toScene: scene,
						hasAudio: hasAudio?.length ? true : false,
						muted: isMuted
					});
					break;
				}
			}
	};

	const findPreviousOfId = () => {
		try {
			// let breadcrumbsCopy = breadcrumbs;
			breadcrumbs.splice(-1);
			let lastScene = breadcrumbs.splice(-1);
			return lastScene[0]._id;
		} catch (error) {}
	};

	const getHistoryList = () => {
		let tmp = [];
		let scenes = {};
		const last = [...history.current].pop();
		for (let h of history.current) {
			let from = h.fromScene;
			let answ = from.answers.filter((a) => a.sceneId === h.to).map((x) => x.answer);
			// do not do this next step if it is a FreeText template continuing to an external URL
			if (Array.isArray(answ) && answ.length === 0 && h.to !== 'external URL') answ = 'Zurück Button';
			else {
				answ = answ[0];
				if (h.fromSceneLabel && h.fromSceneLabel !== '') {
					answ = h.fromSceneLabel;
				}
			}

			let price = h.fromScene.price && h.fromScene.price;
			let formatedStep = '';
			if(h.hasAudio){
				formatedStep = `(${from.title}): ${from.question} => ${answ} [audio muted: ${h.muted}]`;
			}else{
				formatedStep = `(${from.title}): ${from.question} => ${answ}`;
			}
			tmp.push(formatedStep);

			//add last currentScene (exit scene) to history array
			if (last === h) {
				// if FreeText template, just push '(external URL): '
				tmp.push(`(${last.toScene.title || last.toScene}): ${last.toScene.question || ''}`);
			}

			Object.assign(scenes, {
				[from.title + '.title']: from.title,
				[from.title + '.price']: price ? price : null,
				[from.title + '.question']: from.question,
				[from.title + '.answer']: from.answer ? from.answer : answ,
			});
		}
		return {
			history: tmp,
			scenes: scenes,
			// projectName: selectedProject.title, // obsolete? 13.11.2023
		};
	};

	const projectNotFoundMessage = () => {
		return (
			<div>
				<h1>Project not found</h1>
				<p>
					Make sure Project with ID: <strong>{projectID}</strong> <br />
					exists on {HOST}{' '}
				</p>
			</div>
		);
	};

	const CustomCSS = ({ scope }) => {
		let hasCustomCss = false;
		let customCss = '';
		switch (scope) {
			case 'project':
				hasCustomCss = (selectedProject?.hasCustomCss && selectedProject?.customCss !== '') || false;
				customCss = selectedProject?.customCss;
				break;
			case 'scene':
				hasCustomCss = (currentScene?.hasCustomCss && currentScene?.customCss !== '') || false;
				customCss = currentScene?.customCss;
				break;
			default:
				return
		}
		return hasCustomCss &&
			<style>
				{customCss}
			</style>
	}

	const JsSnippet = () => {
		const hasSnippet = (currentScene?.hasCodeSnippet && currentScene?.codeSnippet !== '') || false;
		const codeSnippet = `${currentScene?.codeSnippet}`;
		return hasSnippet &&
				<Helmet>
					<script>{codeSnippet}</script>
				</Helmet>
	}

	return (
		<div
			id={`project-${selectedProject?._id}`}
			className={classNames(styles.container, styles._clickMaster, '_clickMaster')}
		>
			<JsSnippet />
			<CustomCSS scope='project' />
			<CustomCSS scope='scene' />
			{projectNotFound && projectNotFoundMessage()}
			{currentScene && (
				<React.Fragment>
					{hasBackButton && <ChevronLeft width="40" height="40" fill={'transparent'} stroke={projectButtonBgColor} className={styles.backButton} onClick={() => goBack()} />}
					{hasAudio && (
						<AudioPlayer audioUrl={currentScene?.audioFileUrl} autoplay={currentScene.autoplay} isFirstScene={currentScene.isFirstScene} sidebar={currentScene.sidebar} project={selectedProject} />
					)}

					<Template
						scene={currentScene}
						goTo={setCurrentSceneById}
						project={selectedProject}
						templateType={currentScene.template}
						goBack={() => {
							let previous = findPreviousOfId();
							if (previous) setCurrentSceneById(previous);
						}}
						backButton={currentScene.hasBackButton}
						firstSceneId={firstSceneId}
						hideSceneName
						history={getHistoryList()}
						autoplay={true}
						showVideo={true}
						sidebar={false}
						getSessionCompletedTime={getSessionCompletedTime}
					/>
				</React.Fragment>
			)}
		</div>
  )
}

export default Widget;