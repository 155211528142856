import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './VideoTemplate.module.scss';
import videoTemplateImage from '../../assets/images/VideoSceneThumbDefualt.svg';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Button from '../Button/Button';
import { ELEMENTS } from '../../utils/elements';
import { handleAnswerClick } from '../../utils/handleAnswerClick';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

function VideoTemplate(props) {
	let backgroundImage = props.scene.videoFileUrl ? props.scene.videoFileUrl.slice(0, -4) + '.jpg' : videoTemplateImage;
	function isFirstScene() {
		return props.firstSceneId === props.scene._id;
	}

	function getVideoURL() {
		let VIDEO_QUALITY = 70;
		let videoURL = props.scene.videoFileUrl;
		let videoURLSplited = videoURL.split('/');
		videoURLSplited[5] += '/q_' + VIDEO_QUALITY;
		return videoURLSplited.join('/');
	}


	const animationContainerRef = useRef();
	const { contextSafe } = useGSAP({ scope: animationContainerRef });

	const initGsapAnimation = contextSafe(() => {
		gsap.from(`.${ELEMENTS.ANIMATION_ITEM}`, {opacity: 0, y: 30, stagger: 0.1});
	});


	useEffect(() => {
		initGsapAnimation();
	}, [props.scene._id]);

	return (
		<React.Fragment>
			<div
				className={classNames(styles.videoTemplateWrapper, styles._clickMaster)}
				id={`scene-${props.scene._id}`}
				ref={animationContainerRef}
				style={{
					backgroundColor: props?.project?.general?.backgroundColor?.hex,
				}}
			>
				{props.showVideo && props.scene.videoFileUrl ? (
					<VideoPlayer
						videoUrl={getVideoURL()}
						backgroundImage={backgroundImage}
						autoplay={props.autoplay}
						className={classNames(styles.videoPlayer, ELEMENTS.ANIMATION_ITEM )}
						isFirstScene={isFirstScene()}
					/>
				) : (
					<div className={styles.template}>{props.children}</div>
				)}

				<div className={`${styles.content}`}>
					<span className={styles.templateRow}>
						{/*{!props.hideSceneName && (*/}
						{/*	<div className={styles.titleWrapper}>*/}
						{/*		<span className={styles.title}>{props.scene && props.scene.title}</span>*/}
						{/*	</div>*/}
						{/*)}*/}
						<h1 className={classNames(ELEMENTS.SCENE_TITLE, ELEMENTS.ANIMATION_ITEM, styles.question)} style={{ color: props.project?.general?.color?.hex }}>
							{props.scene && props.scene.question}
						</h1>
						{props?.scene?.richText && <p className={classNames(ELEMENTS.SCENE_DESCRIPTION, ELEMENTS.ANIMATION_ITEM, styles.richText)} dangerouslySetInnerHTML={{ __html: props.scene.richText }} style={{ color: props.project?.general?.color?.hex }}></p>}
						<div className={styles.buttonsContainer}>
							{props.scene &&
								props.scene.answers.map((answer, index) => (
									<div
										className={classNames(styles.buttonWrapper, ELEMENTS.ANIMATION_ITEM, {
											[styles.buttonWrapperSmall]: props.scene?.answers.length > 3,
										})}
										key={index}
									>
										<Button
											key={answer.answer + props.scene._id}
											label={answer.answer}
											secondary
											onClick={() => handleAnswerClick(null, props.goTo, answer, answer.answer)}
											style={{
												minWidth: props.scene.answers.length === 4 ? '80px' : '',
												color: props.project?.general?.buttonColor?.hex,
												backgroundColor: props.project?.general?.buttonBackgroundColor?.hex,
												background: props.project?.general?.buttonBackgroundColor?.hex,
											}}
										/>
									</div>
								))}
						</div>
					</span>
				</div>
			</div>
		</React.Fragment>
	);
}

export default VideoTemplate;