import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
// TODO: cleanup sourcing css from original Template sccss and add custom scss classes to the local TextImageTemplate.module.scss instead
import styles from '../Template/Template.module.scss';
import { ELEMENTS } from '../../utils/elements';
import Button from '../Button/Button';
import { handleAnswerClick } from '../../utils/handleAnswerClick';
import getOptimizedImage from '../../utils/getOptimizedImage';
import { CONSTANTS, LOTTIE_FILE_EXTENSIONS } from '../../utils/constants';
import LottiePlayer from '../Lottie/LottiePlayer';
import { useTranslation } from '../../utils/useTranslation';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';


const RenderImage = ({scene, project, projectColors}) => {  // for 'text_image' templates
	const t = useTranslation(project?.language);
	let imageUrl = CONSTANTS.imageTemplate;

	if (scene.imageUrl) {
		imageUrl = scene?.retrieveOrigSize ?
			scene.imageUrl :
			getOptimizedImage(scene.imageUrl, {width: 450, height: 450, quality: 90});
	}
	const isLottie = LOTTIE_FILE_EXTENSIONS.includes(imageUrl.split('.').pop());
	const renderImage = <img src={imageUrl}
													 alt={scene.imageDescription} title={scene.imageDescription}
													 className={classNames(styles.image, ELEMENTS.ANIMATION_ITEM)} />;
	const renderLottie = <div className={classNames(styles.lottie, styles.image)}><LottiePlayer src={imageUrl} /></div>;
	return (
		<React.Fragment>
			<div className={styles.imgContainer} >
				{isLottie ? renderLottie : renderImage}

				{scene.price && (
					<span className={styles.priceBubble} style={{ backgroundColor: projectColors.ButtonBgColor }}>
							<span className={styles.priceLabel} style={{ color: projectColors.ButtonColor }}>
								{t.template.priceFrom}
							</span>
							<span className={styles.price} style={{ color: projectColors.ButtonColor }}>
								{scene.price}
							</span>
						</span>
				)}
			</div>
		</React.Fragment>
	);
}

const TextImageTemplate = (props) => {
	const projectColors = {
		BgColor: props.project?.general?.backgroundColor?.hex,
		TextColor: props.project?.general?.color?.hex,
		ButtonColor: props.project?.general?.buttonColor?.hex,
		ButtonBgColor: props.project?.general?.buttonBackgroundColor?.hex
	}
	const animationContainerRef = useRef();
	const { contextSafe } = useGSAP({ scope: animationContainerRef });

	const initGsapAnimation = contextSafe(() => {
		gsap.from(`.${ELEMENTS.ANIMATION_ITEM}`, {opacity: 0, y: 30, stagger: 0.1});
	});


	useEffect(() => {
		initGsapAnimation();
	}, [props.scene._id]);

	return (
		<React.Fragment>
			<div id={`scene-${props.scene._id}`} // TODO: make this id appear only once in code for all the scene templates
				className={classNames(styles.template, styles._clickMaster, styles.templateImage)}
				style={{ backgroundColor: projectColors.BgColor}}
			 	ref={animationContainerRef}
			>

				<RenderImage scene={props.scene} project={props.project} projectColors={projectColors} />
				<div className={classNames(styles.templateRow, styles.templateCol)}>

					<h1
						className={classNames(ELEMENTS.SCENE_TITLE, ELEMENTS.ANIMATION_ITEM, styles.question, styles.questionImage)}
						style={{ color: projectColors.TextColor }}
					>
						{props.scene?.question}{' '}
					</h1>
					{props.scene?.richText && (
						<div
							className={classNames(ELEMENTS.SCENE_DESCRIPTION, ELEMENTS.ANIMATION_ITEM, styles.description, styles.withImage)}
							dangerouslySetInnerHTML={{ __html: props.scene.richText }}
							style={{ color: projectColors.TextColor }}
						></div>
					)}
					<div className={classNames(styles.buttonsContainer, styles.withImage)}>
						{props.scene?.answers.map((answer, index) => (
							<div
								className={classNames(ELEMENTS.SCENE_ANSWER_LABEL, ELEMENTS.ANIMATION_ITEM, styles.buttonWrapper, {
									[styles.buttonWrapperSmall]: props.scene?.answers.length > 3,
								})}
								key={index}
							>
								<Button
									key={answer.answer + props.scene._id}
									label={answer.answer}
									secondary
									onClick={() => handleAnswerClick(null, props.goTo, answer, answer.answer)}
									style={{
										color: projectColors.ButtonColor,
										backgroundColor: projectColors.ButtonBgColor,
									}}
								/>
							</div>
						))}
					</div>
				</div>
				{props.children}
			</div>
		</React.Fragment>
	)
}

export default TextImageTemplate;