import React from 'react';
import classNames from 'classnames';
import { ELEMENTS } from '../../utils/elements';
import styles from './Input.module.scss';

function Input(props) {
	function renderInput() {
		if (props.type === 'textarea') {
			return (
				<textarea
					value={props.value}
					name={props.name}
					onChange={(e) => props.onChange(e)}
					onBlur={(e) => props.onBlur && props.onBlur(e)}
					className={classNames(ELEMENTS.TEXTAREA, styles.input, styles.textarea, styles._dbw, {
						[styles.secondary]: props.secondary,
						[styles.templateTextarea]: props.template,
					})}
					rows={props.rows}
					placeholder={props.placeholder}
				/>
			);
		} else {
			return (
				<React.Fragment>
					{props.icon && <img src={props.icon} className={styles.icon} alt="icon" />}
					<input
						type={props.type}
						value={props.value}
						name={props.name}
						onChange={(e) => {
							props.onChange(e);
						}}
						onBlur={(e) => props.onBlur && props.onBlur(e)}
						placeholder={props.placeholder}
						className={classNames(styles.input, styles._dbw, {
							[styles.primary]: props.primary,
							[styles.secondary]: props.secondary,
							[styles.ternary]: props.ternary,
							[styles.template]: props.template,
							},
							ELEMENTS.INPUT
						)}
					/>
				</React.Fragment>
			);
		}
	}
	return (
		<div className={classNames(styles.inputWrapper, styles.inputWrapperTemplate, styles._dbw)}>
			{renderInput()}
			<div
				className={classNames(styles.error, styles._dbw, {
					[styles.show]: props.error,
					[styles.errorPrimary]: props.primary,
					[styles.errorSecondary]: props.secondary,
					[styles.errorTernary]: props.ternary,
				})}
				style={props.errorstyle && props.errorstyle}
			>
				{props.error}
			</div>
		</div>
	);
}

export default Input;