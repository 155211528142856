export const ELEMENTS = Object.freeze({
	BUTTON: 'DB_Button',
	INPUT: 'DB_input',
	TEXTAREA: 'DB_textarea',
	SCENE_TITLE: 'DB_SceneTitle',
	SCENE_DESCRIPTION: 'DB_SceneDescription',
	SCENE_IMAGE_TITLE: 'DB_ImageTitle',
	SCENE_RICH_TEXT: 'DB_RichText',
	SCENE_ANSWER_LABEL: 'DB_AnswerLabel',
	SCENE_TOS: 'DB_TOS',
	SCENE_JSON_SOURCE: 'DB_JsonSource',
	SCENE_ANSWER_COLUMN: 'DB_AnswerColumn',
	ANIMATION_ITEM: "CM_animation_item"
});