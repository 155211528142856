import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css'
import classNames from "classnames";
import { hideModal, showModal } from "../../actions/modalActions";
import { ELEMENTS } from "../../utils/elements";
import { validateEmail } from '../../utils/validateEmail';
import { useTranslation } from "../../utils/useTranslation";
import Input from "../Input/Input";
import styles from './ContactTemplate.module.scss';
import Button from "../Button/Button";
import { getUTMObject } from "../../utils/getUTMObject";
import { handleAnswerClick } from '../../utils/handleAnswerClick';
import { DATE_FORMAT } from '../../utils/constants';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const apiUrl = process.env.REACT_APP_BE_URL;

// called by Template.js
const ContactTemplate = ({ project, scene, history, getSessionCompletedTime, goTo, hideSceneName, setChildIndex }) => {
	const t = useTranslation(project?.language);
	const dispatch = useDispatch();

  const [answers, setAnswers] = useState({});
	const [submitted, setSubmitted] = useState(false);
	const [datePickerFocused, setDatePickerFocused] = useState({});

	const contact = scene?.contact;
	const projectColor = project?.general?.color?.hex;
	const allHiddenFields = project?.hiddenFields || [];
	// remove any empty key-value pairs
	const hiddenFields = allHiddenFields.filter((field) => field.key !== '' || field.value !== '');

	const animationContainerRef = useRef();
	const { contextSafe } = useGSAP({ scope: animationContainerRef });

	const initGsapAnimation = contextSafe(() => {
		gsap.from(`.${ELEMENTS.ANIMATION_ITEM}`, {opacity: 0, y: 30, stagger: 0.1});
	});


	useEffect(() => {
		initGsapAnimation();
	}, [scene._id]);

  useEffect(() => {
    // get utms
		const UTMFields = getUTMObject(window.location.search);

    /* initialize answers object to control answers to contact form
       and, if applicable, datePickerFocused object
			answers: {
			 <fieldname>: {
				 answer: <answer>,
				 required: <boolean>,
				 errMsg: <errMsg>
			 },
			}
 		*/
    const answerObject = {};
    if (contact?.fields) {
      contact.fields.forEach(field =>{
        answerObject[field.name] = {};
        answerObject[field.name].answer = UTMFields[field.name] || '';
        answerObject[field.name].required = field.requiredField || false;
        answerObject[field.name].errMsg = '';
        if (field.type === 'multipleChoice') {
          answerObject[field.name].answer = (UTMFields[field.name] && UTMFields[field.name].split(', ')) || [];
        }
				if (field.type === 'date') datePickerFocused[field.name] = false;
      })
    };
    setAnswers(answerObject);
	// eslint-disable-next-line react-hooks/exhaustive-deps
  },[contact]);

	function showPopup(title, subtitle) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				subtitle: subtitle,
				buttonPrimary: {
					label: t.popup.ok,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	async function handleSubmit(answer) {
    if (!contact.emailLink) {
      showPopup(t.template.missingVendorEmail, t.template.contactSupport);
      return
    }
		let error = false;
    for (const field in answers) {
      let errMsg = '';
      if ((answers[field].required && !answers[field].answer) || answers[field].errMsg) {  // dropdowns may already have an error message
        errMsg = t.login.fieldIsRequired;
      } else if (answers[field].answer && field.toLowerCase() === 'email' && !validateEmail(answers[field].answer)) {
        errMsg = t.login.emailInvalid;
      }
      const newAnswer = answers[field];
      newAnswer.errMsg = errMsg
      setAnswers({...answers, [field]: newAnswer});
      if (errMsg) error = true;
    }

		if (!error) {
      /* build leaner answer object like this:
          { <fieldname1>: <answer>,
            <fieldname2>: <answer>
          }
      */
      const formAnswers = {}
      for (const field in answers) {
        formAnswers[field] = answers[field].answer;
        if (Array.isArray(formAnswers[field])) {
          formAnswers[field] = formAnswers[field].join(', ')
        } else if (formAnswers[field]._isAMomentObject) {  // convert from moment object to javascript Date object
					formAnswers[field] = new Date(formAnswers[field]._d)
				}
      }

      let sourceUrl = window.location.href;
			let host = window.location.origin;
      let webhookURL = project?.webhookURL || null;
      let urlString = window.location.search;
      let urlParams = new URLSearchParams(urlString);
      let entries = urlParams.entries();
      let urlEntries = [];
      for (let entry of entries) {
        urlEntries.push({
          [entry[0]]: entry[1]
        })
      }
      let data = {
        projectId: project._id,
        projectTitle:	project.title,
      	projectAuthorId: project.userId,
        formAnswers,
				webhookOnly: contact.webhookOnly,
        projectEmail: project.sendToEmail ? project.sendToEmail : contact.emailLink,
        projectCcEmail: contact.ccEmail,
				sendToEmail: project.sendToEmail,
        UTM: urlEntries,
        scenes: history.scenes ? history.scenes : null,
        // projectName: history.projectName, // obsolete? 13.11.2023
				history: history.history,
        host,
        sourceUrl,
        webhookURL,
        hiddenFields,
				language: project.language
      };

			getSessionCompletedTime();

      /* send data to backend to handle sending contact info via email and
         webhook and to save submission to database (if applicable)  */
      fetch(apiUrl + '/scene/contact', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ data }),
      })
      .then( () => {
				setSubmitted(true);
				//If there is no next scene or url
        if (!answer.sceneId && !answer.url) {
          showPopup(t.template.submissionSuccess);
        } else {
					handleAnswerClick(null, goTo, answer)
					if (answer.newTab) showPopup(t.template.submissionSuccess)
				}
      })
      .catch((err) => showPopup(t.template.submissionFailed, t.template.contactSupport))
		}
	}

  const widthClass = (width) => {
    switch (width) {
      case "33%":
        return 'width-33'
      case "50%":
        return 'width-50'
      case "66%":
        return 'width-66'
      case "100%":
        return 'width-100'
      default:
        return 'width-100'
    }
  }

	return (
		<React.Fragment>
			<div
        className={classNames(styles.ContactTemplate, styles._clickMaster)}
        id={`scene-${scene?._id}`}
        style={{
					backgroundColor: project?.general?.backgroundColor?.hex,
				}}
				ref={animationContainerRef}
      >
				<div className={classNames(styles.templateRow)}>
					{!hideSceneName && (
						<div className={styles.titleWrapper}>
							<span className={styles.title}>{scene?.title}</span>
						</div>
					)}
					<h1 className={classNames(ELEMENTS.SCENE_TITLE, ELEMENTS.ANIMATION_ITEM, styles.question)} style={{ color: projectColor }}>
						{scene && scene.question}{' '}
					</h1>
          <div
            dangerouslySetInnerHTML={{ __html: contact.description }}
            className={classNames(ELEMENTS.SCENE_DESCRIPTION, ELEMENTS.ANIMATION_ITEM, styles.description)}
            style={{color: projectColor}}
					/>
					<div className={classNames(styles.inputRow, ELEMENTS.ANIMATION_ITEM)}>
            {contact.fields && Object.keys(answers).length > 0 &&
              contact.fields.map((field, idx) => {
                if (!answers[field.name]) return null // ensure answers have updated on successive contact forms
                // let header;
                let options;
                if (field.type === 'dropdown' || field.type === 'multipleChoice') {
                  options = field.options.trim().split('\n');
                  // header = options.shift();
									if (field.type === 'dropdown') {
										if (field.placeholder && field.placeholder !== t.template.placeholder) options.unshift(field.placeholder);
										if (field.requiredField) options[0] += '*';
									}
                }
                const fieldWidth = widthClass(field.fieldWidth)
                return (
                  <div key={idx}
                    className={classNames(styles[fieldWidth])}
                  >
										<div className={styles.inputWrapper}>

                      {(field.type === 'shortText' || field.type === 'textarea') &&
                        <Input
                          type={field.type}
                          value={answers[field.name].answer}
                          name={field.name}
                          onChange={(e) => {
                            const newAnswer = answers[field.name];
                            newAnswer.answer = e.target.value;
                            newAnswer.errMsg = '';
                            setAnswers({...answers, [field.name]: newAnswer});
                          }}
                          // squared
                          rows={3}
                          template
                          placeholder={field.placeholder + (field.requiredField ? '*' : '')}
                          error={answers[field.name].errMsg}
                          errorstyle={{ left: '0px', textAlign: 'center' }}
                        />
                      }
                      {(field.type === 'dropdown') && <div className={styles.dropdown}>
                        {/* <label className={styles.dropdownLabel}>{header}:</label> */}
                        <select name={field.name}
                          onChange={(e) => {
                            const selection = e.target.value;
                            const newAnswer = answers[field.name];
                            newAnswer.answer = selection;
                            // if (field.requiredField && selection === options[0]) {
                            //   newAnswer.errMsg = t.login.fieldIsRequired;
                            // } else newAnswer.errMsg = '';
                            newAnswer.errMsg = '';
                            setAnswers({...answers, [field.name]: newAnswer});
                          }}
                          defaultValue={answers[field.name].answer || options[0]}
                          className={answers[field.name].answer ? styles.darkText : styles.lightText}
                        >
                          {options.map((option,idx) => {
                          return <option key={option} value={option} disabled={!idx}
                                className={idx ? styles.darkText : styles.lightText}
                              >
                                {option}
                              </option>
                          })}
                        </select>
                        {answers[field.name].errMsg && <div className={styles.dropdownError}>
                          {answers[field.name].errMsg}
                        </div>}
                      </div>}
                      {(field.type === 'checkbox') &&
                        <div className={styles.checkboxContainer} style={{ display: 'block' }}>
                          <div className={styles.checkboxWrapper}>
                            <input type="checkbox"
                              checked={answers[field.name].answer || false}
                              onChange={(e) => {
                                const newAnswer = answers[field.name];
                                newAnswer.answer = !newAnswer.answer;
                                newAnswer.errMsg = '';
                                setAnswers({...answers, [field.name]: newAnswer});
                              }}
                            />
                            <div
                              dangerouslySetInnerHTML={{ __html: field.checkboxText }}
                              className={classNames(ELEMENTS.SCENE_TOS, styles.checkboxLink)}
                              style={{color: projectColor}}
                            ></div>
                          </div>
                          <div className={classNames([styles.error, styles.tos_error], { [styles.show]: answers[field.name].errMsg })}>
                            {answers[field.name].errMsg}
                          </div>
                        </div>
                      }
											{(field.type === 'date') &&
												<div className={styles.dateContainer}>
													<SingleDatePicker
														date={answers[field.name].answer || null} // momentPropTypes.momentObj or null
														onDateChange={date => {
															const newAnswer = answers[field.name];
															newAnswer.answer = date;
															newAnswer.errMsg = '';
															setAnswers({...answers, [field.name]: newAnswer});
														}} // PropTypes.func.isRequired
														focused={datePickerFocused[field.name]} // PropTypes.bool
														onFocusChange={({ focused }) => {
															setDatePickerFocused({...datePickerFocused, [field.name]: focused});
														}} // PropTypes.func.isRequired
														id={`picker-${field.name}`} // PropTypes.string.isRequired,
														placeholder={field.placeholder} // PropTypes.string,
														displayFormat={DATE_FORMAT}
														noBorder
														withPortal // avoid date selector opening up below the screen
														firstDayOfWeek={1} // start with Monday (default is 0 for Sunday)
													/>
													{answers[field.name].errMsg && <div className={styles.dropdownError}>
														{answers[field.name].errMsg}
													</div>}
												</div>
											}
											{(field.type === 'multipleChoice' && field.placeholder && field.placeholder !== t.template.placeholder) &&
												<div className={styles.multiChoiceLabel}>
													{field.placeholder}
												</div>
											}
                      {(field.type === 'multipleChoice') && options.map(option => {
                        return (
                          <div key={option} className={styles.checkboxContainer} style={{ display: 'block' }}>
                            <div className={styles.checkboxWrapper}>
                              <input type="checkbox"
                                checked={answers[field.name].answer.includes(option)}
                                onChange={(e) => {
                                  const newAnswer = answers[field.name];
                                  newAnswer.answer.includes(option) ?
                                    newAnswer.answer = newAnswer.answer.filter(ans => ans !== option)
                                    : newAnswer.answer.push(option);
                                  newAnswer.errMsg = '';
                                  setAnswers({...answers, [field.name]: newAnswer});
                                }}
                              />
                              <label
                                className={classNames(ELEMENTS.SCENE_TOS, styles.checkboxLink)}
                                style={{color: projectColor}}
                              >
                                {option}
                              </label>
                            </div>
                            <div className={classNames([styles.error, styles.tos_error], { [styles.show]: answers[field.name].errMsg })}>
                              {answers[field.name].errMsg}
                            </div>
                          </div>)
                      })}
										</div>
                  </div>)
            })}

          </div>

					<div className={classNames(styles.buttonsContainer, ELEMENTS.ANIMATION_ITEM)} style={{ display: 'block' }}>
						{scene &&
							scene.answers.map((answer, index) => (
								<div className={styles.buttonWrapper} key={index}
                >
									<Button
										key={answer.answer + scene._id}
										label={answer.answer}
										secondary
										onClick={() => handleSubmit(answer)}
										disabled={submitted}
										style={{
											minWidth: scene.answers.length === 4 && '80px',
											color: project?.general?.buttonColor?.hex,
											backgroundColor: project?.general?.buttonBackgroundColor?.hex,
											background: project?.general?.buttonBackgroundColor?.hex,
										}}
									/>
								</div>
							))}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default ContactTemplate;