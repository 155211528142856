/* Called by Template.js (for 'text_image' templates) and by
	 ColumnsTemplate.js (for 'columns' and 'images' templates).
	 This function reduces download size to improve performance. */
const getOptimizedImage = (url, config = {}) => {
	let {width, height, quality} = config; 
	width = width || 700;
	height = height || 700;
	quality = quality || 'auto';
	let urlSplited = url.split('/');
	urlSplited[5] += `/w_${width},h_${height}/q_${quality}`;
	return urlSplited.join('/');
};

export default getOptimizedImage; 