import React, { useEffect, useRef, useState } from 'react';
import classNames from "classnames";
import { ELEMENTS } from "../../utils/elements";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { useTranslation } from "../../utils/useTranslation";
import styles from './FreeTextTemplate.module.scss';
import { handleAnswerClick } from '../../utils/handleAnswerClick';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';


/*  called by Template.js
    sends user-given answer through props.goTo() to Template.js and from there to
    Widget.js to setCurrentSceneById() as the second argument 'answerLabel' and
    where it is then added to the session history
*/
const FreeTextTemplate = (props) => {
  const t = useTranslation(props?.project?.language);
  const [answer, setAnswer] = useState('');
  const [requiredFieldErrMsg, setRequiredFieldErrMsg] = useState('');

	const freeText = props.scene && props.scene.freeText;
	const projectColor = props?.project?.general?.color?.hex;

  useEffect(()=>{
    setAnswer('')  // reset if new scene loads
  }, [props])

	const animationContainerRef = useRef();
	const { contextSafe } = useGSAP({ scope: animationContainerRef });

	const initGsapAnimation = contextSafe(() => {
		gsap.from(`.${ELEMENTS.ANIMATION_ITEM}`, {opacity: 0, y: 30, stagger: 0.1});
	});


	useEffect(() => {
		initGsapAnimation();
	}, [props.scene._id]);


	const handleSubmit = () =>{
    const sceneAnsObject = props.scene.answers[0]
    if (!answer && freeText.required) setRequiredFieldErrMsg(t.login.fieldIsRequired);
		else {
			const historyText = answer ? answer : freeText.defaultAnswer;
			handleAnswerClick(null, props.goTo, sceneAnsObject, historyText);
			if (sceneAnsObject.external && sceneAnsObject.url) {
				props.goTo('FreeText External URL', historyText)
				props.getSessionCompletedTime();
			}
		}
  }

	return (
		<React.Fragment>
			<div
        className={classNames(styles.ContactTemplate, styles._clickMaster)}
        id={`scene-${props.scene._id}`}
				ref={animationContainerRef}
        style={{
					backgroundColor: props?.project?.general?.backgroundColor?.hex,
				}}
      >
				<div className={classNames(styles.templateRow)}>
					{/*{!props.hideSceneName && (*/}
					{/*	<div className={styles.titleWrapper}>*/}
					{/*		<span className={styles.title}>{props.scene?.title}</span>*/}
					{/*	</div>*/}
					{/*)}*/}
					<h1 className={classNames(ELEMENTS.SCENE_TITLE, ELEMENTS.ANIMATION_ITEM, styles.question)} style={{ color: projectColor }}>
						{props.scene && props.scene.question}{' '}
					</h1>
					{freeText.description && <div
						dangerouslySetInnerHTML={{ __html: freeText.description }}
						className={classNames(ELEMENTS.SCENE_DESCRIPTION, ELEMENTS.ANIMATION_ITEM, styles.description)}
						style={{color: projectColor}}
					/>}

					<div className={classNames(styles.inputRow, ELEMENTS.ANIMATION_ITEM)}>
            <Input
              type={freeText.inputType}
              value={answer}
              // name={field.name}
              onChange={(e) => {
                setAnswer(e.target.value);
                setRequiredFieldErrMsg('');
              }}
              // squared
              rows={3}
              template
              placeholder={freeText.placeholder + (freeText.required ? '*' : '')}
              error={requiredFieldErrMsg}
              errorstyle={{ left: '0px', textAlign: 'center' }}
            />
          </div>

					<div className={classNames(styles.buttonsContainer, ELEMENTS.ANIMATION_ITEM)} style={{ display: 'block' }}>
						<div className={styles.buttonWrapper} >
              <Button
                label={props.scene.answers[0].answer}
                secondary
                onClick={() => handleSubmit()}
                style={{
                  minWidth: props.scene.answers.length === 4 && '80px',
                  color: props?.project?.general?.buttonColor?.hex,
                  backgroundColor: props?.project?.general?.buttonBackgroundColor?.hex,
                  background: props?.project?.general?.buttonBackgroundColor?.hex,
                }}

              />
            </div>

					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default FreeTextTemplate;