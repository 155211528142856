import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from '../../utils/useTranslation';
import styles from './JsonTemplate.module.scss';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { hideModal, showModal } from '../../actions/modalActions';
import Loader from 'react-loader-spinner-svg';
import { setJsonTemplateKeys } from '../../actions/jsonTemplateActions';
import Parser from 'html-react-parser';
import { ELEMENTS } from '../../utils/elements';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

function JsonTemplate(props) {
	const t = useTranslation(props?.project?.language);
	const dispatch = useDispatch();
	const [searchKey, setSearchKey] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [searchValueError, setSearchValueError] = useState('');
	const [fileData, setFileData] = useState([]);
	const [foundObject, setFoundObject] = useState(null);
	const [loading, setLoading] = useState(false);
	const [inputSugestions, setInputSugestions] = useState(null);
	const projectColor = props?.project?.general?.color?.hex;

	useEffect(() => {
		// props.scene.jsonFileUrl && readJsonFile(props.scene.jsonFileUrl);
		if (props.scene.jsonFileUrl) {
			fetch(props.scene.jsonFileUrl)
				.then(res => res.json())
				.then(json => setFileData(json))
				.catch(() => showPopup(t.jsonTemplate.fileNotFound, '', t.jsonTemplate.pleaseUploadFile))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.scene.jsonFileUrl]);

	useEffect(() => {
		props.scene.jsonSearchKey && setSearchKey(props.scene.jsonSearchKey);
	}, [props.scene.jsonSearchKey]);

	useEffect(() => {
		if (fileData) {
			//Getting avilable keys from JSON file
			let keys = [];
			if (fileData.length > 0) {
				for (let d of fileData) {
					let objectKeys = Object.keys(d);

					for (let key of objectKeys) {
						keys.push(key);
					}
				}

				let removedDuplicateKeys = keys.filter(function(value, index, array) {
					return array.indexOf(value) === index;
				});
				if (removedDuplicateKeys) {
					dispatch(setJsonTemplateKeys(removedDuplicateKeys));
				}
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileData]);

	const animationContainerRef = useRef();
	const { contextSafe } = useGSAP({ scope: animationContainerRef });

	const initGsapAnimation = contextSafe(() => {
		gsap.from(`.${ELEMENTS.ANIMATION_ITEM}`, {opacity: 0, y: 30, stagger: 0.1});
	});


	useEffect(() => {
		initGsapAnimation();
	}, [props.scene._id]);

	function showPopup(title, answer, subtitle) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				subtitle: subtitle,
				buttonPrimary: {
					label: t.popup.ok,
					action: () => {
						dispatch(hideModal());
					},
				},
			}),
		);
	}

	// function readJsonFile(url) {
	// 	readTextFile(`${url}`, function(text) {
	// 		var data = JSON.parse(text); //parse JSON
	// 		setFileData(data);
	// 	});
	// }

	// function readTextFile(file, callback) {
	// 	var rawFile = new XMLHttpRequest();
	// 	rawFile.overrideMimeType('application/json');
	// 	rawFile.open('GET', file, true);
	// 	rawFile.onreadystatechange = function() {
	// 		if (rawFile.readyState === 4 && rawFile.status === 200) {
	// 			callback(rawFile.responseText);
	// 		}
	// 	};
	// 	rawFile.send(null);
	// }

	function handleSubmit() {
		if (props.scene.jsonFileUrl === undefined) {
			return showPopup(t.jsonTemplate.fileNotFound, '', t.jsonTemplate.pleaseUploadFile);
		}
		const TIMEOUT = 1000;
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, TIMEOUT);

		if (searchValue.length < 1) {
			return setSearchValueError(t.jsonTemplate.enterValidInput);
		}

		let noResultsFound = true;
		if (fileData) {
			try {
				for (let data of fileData) {
					if (data[searchKey].toString().toLocaleLowerCase() === searchValue.toString().toLocaleLowerCase()) {
						setFoundObject(data);
						noResultsFound = false;
					}
				}
			} catch (error) {
				return showPopup(t.jsonTemplate.wrongJsonKey, '', t.jsonTemplate.checkJsonKeyValue);
			}
		}
		if (noResultsFound) {
			return setSearchValueError(t.jsonTemplate.noResultForSelection);
		}
		setSearchValueError('');
	}

	function handleChange(e) {
		setSearchValueError('');
		let allValuesForSearchKey = [];
		for (let data of fileData) {
			allValuesForSearchKey.push(data[searchKey]);
		}
		const newSearchValue = e.target.value;
		setSearchValue(newSearchValue);
		if (newSearchValue.length > 0) {
			let suggestions = [];
			try {
				suggestions = allValuesForSearchKey.length &&
					allValuesForSearchKey.filter(suggestion =>
						suggestion.toString().toLowerCase().startsWith(newSearchValue.toString().toLowerCase())
				);
				// suggestions = allValuesForSearchKey && allValuesForSearchKey.filter((suggestion) => suggestion.toString().toLowerCase().indexOf(newSearchValue.toString().toLowerCase()) > -1);
				setInputSugestions(
					suggestions.map((suggestion, index) => {
						return (
							<li key={index} className={'item'} onClick={() => handleClick(suggestion)}>
								{suggestion}
							</li>
						);
					}),
				);
			} catch (error) {
				return showPopup('Something is wrong with JSON search key', '', 'JSON search key must be same as one in file');
			}
		}
	}

	function handleClick(suggestion) {
		setSearchValue(suggestion);
		setInputSugestions(null);
	}

	function formatDescription(text) {
		let splitedText = text.split(' ');
		let counter = 0;
		for (let word of splitedText) {
			let openingBrackets = '{';
			let closingBrackets = '}';

			if (word.includes(openingBrackets) && word.includes(closingBrackets)) {
				const entries = Object.entries(foundObject);
				let wordWithRemovedBrackets = word.replace(/[^a-z]/gi, '');
				// eslint-disable-next-line no-loop-func
				entries.forEach((entry) => {
					if (entry[0].toLocaleLowerCase() === wordWithRemovedBrackets.toLocaleLowerCase()) {
						let entryValue = entry[1];
						splitedText[counter] = `<span style='font-weight: bold'>${entryValue}</span>`;
					}
				});
			}
			counter++;
		}
		let splitedTextString = splitedText.toString();
		// let formatedString = splitedTextString.replaceAll(',', ' ');
		let formatedString = splitedTextString.replace(/,(?!,)/g, ' '); // only replace commas not followed by another comma.
		return (
			<div className={classNames(ELEMENTS.SCENE_DESCRIPTION, ELEMENTS.ANIMATION_ITEM, styles.description)} style={{ color: projectColor }}>
				<p>{Parser(formatedString)}</p>
			</div>
		);
	}

	return (
		<React.Fragment>
			<div
				className={classNames(styles.template)}
				ref={animationContainerRef}
				id={`scene-${props.scene._id}`}
				style={{
					backgroundColor: props?.project?.general?.backgroundColor?.hex,
				}}
			>
				{foundObject !== null ? (
					loading ? (
						<Loader type='TailSpin' color='#00BFFF' height={40} width={40} visible={loading} className={styles.loader} />
					) : (
						<div
							// style={styles.jsonResolution}
						>
							<h1 className={classNames(ELEMENTS.SCENE_TITLE, ELEMENTS.ANIMATION_ITEM, styles.result)} style={{ color: projectColor }}>
								{t.jsonTemplate.result}
							</h1>
							{props.scene.jsonVariablesDescription && formatDescription(props.scene.jsonVariablesDescription)}

							<span className={classNames(styles.buttonsContainer)} style={{ display: 'block' }}>
								<div className={styles.secondSceneButtonWrapper}>
									<Button
										label={t.jsonTemplate.continue}
										secondary
										onClick={() => {
											props.goTo(props.scene.answers[0].sceneId, searchValue);
											setFoundObject(null);
											setSearchValue('');
										}}
										style={{
											minWidth: props.scene.answers.length === 4 && '80px',
											color: props?.project?.general?.buttonColor?.hex,
											backgroundColor: props?.project?.general?.buttonBackgroundColor?.hex,
											background: props?.project?.general?.buttonBackgroundColor?.hex,
										}}
									/>
								</div>
							</span>
							<div className={classNames(ELEMENTS.SCENE_JSON_SOURCE, styles.source)} style={{ color: projectColor }}>
								{props.scene.jsonFileSource}
							</div>
						</div>
					)
				) : (
					<div>
						<span className={classNames(styles.templateRow)}>
							{!props.hideSceneName && (
								<div className={styles.titleWrapper}>
									<span className={styles.title}>{props.scene && props.scene.title}</span>
								</div>
							)}
							<h1 className={classNames(ELEMENTS.SCENE_TITLE, styles.question)} style={{ color: projectColor }}>
								{props.scene && props.scene.question}
							</h1>

							<div className={styles.description}>
								{props.scene && props.scene.richText && (
									<p
										className={classNames(ELEMENTS.SCENE_DESCRIPTION, styles.richText)}
										dangerouslySetInnerHTML={{ __html: props.scene.richText }}
										style={{ color: props.project?.general?.color?.hex }}
									></p>
								)}
							</div>
							<div className={styles.inputRow}>
								<Input
									type='text'
									value={searchValue}
									name='searchKey'
									onChange={(e) => handleChange(e)}
									squared
									template
									// placeholder={searchKey && t.jsonTemplate.here + searchKey.toUpperCase() + t.jsonTemplate.enter}
									placeholder={searchKey && t.jsonTemplate.preSearchKeyText + searchKey + t.jsonTemplate.postSearchKeyText}
									error={searchValueError}
									errorstyle={{ top: '44px', left: '0px', textAlign: 'center', fontSize: '14px' }}
								/>
							</div>
							{inputSugestions && (
								<div className={styles.suggestions}>
									<ul>{inputSugestions}</ul>
								</div>
							)}

							<span className={classNames(styles.buttonsContainer, ELEMENTS.ANIMATION_ITEM)} style={{ display: 'block' }}>
								<div className={styles.buttonWrapper}>
									<Button
										label={t.jsonTemplate.check}
										secondary
										onClick={() => handleSubmit()}
										style={{
											color: props?.project?.general?.buttonColor?.hex,
											backgroundColor: props?.project.general?.buttonBackgroundColor?.hex,
											background: props?.project.general?.buttonBackgroundColor?.hex,
										}}
									/>
								</div>
							</span>
						</span>
						{props.children}
					</div>
				)}
			</div>
		</React.Fragment>
	);
}

export default JsonTemplate;