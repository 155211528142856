const apiUrl = process.env.REACT_APP_BE_URL;

export const fetchSendAnalytics = async (session) => {
	try {
		let sendAnalytics = await fetch(apiUrl + '/session/data/send', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
			},
			body: JSON.stringify(session),
		});
		if (!sendAnalytics) {
			console.log('Saving session failed');
		}
	} catch (error) {
		console.log('error: ', error);
	}
};