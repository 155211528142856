import React from 'react';
import ColumnsTemplate from '../ColumnsTemplate/ColumnsTemplate';
import ContactTemplate from '../ContactTemplate/ContactTemplate';
import FreeTextTemplate from '../FreeTextTemplate/FreeTextTemplate';
import HtmlTemplate from '../HtmlTemplate/HtmlTemplate';
import JsonTemplate from '../JsonTemplate/JsonTemplate';
import VideoTemplate from '../VideoTemplate/VideoTemplate';
import TextTemplate from '../TextTemplate/TextTemplate';
import TextImageTemplate from '../TextImageTemplate/TextImageTemplate';

// Called by src/components/Widget/Widget.js
function Template(props) {

	function isFirstScene() {
		return props.firstSceneId === props.scene._id;
	}

	if (props.templateType === 'contact') {
		return (
			<ContactTemplate
				scene={props.scene}
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				hideSceneName={props.hideSceneName}
				project={props.project}
				history={props.history}
				// autoplay={props.autoplay} // not currently used
				getSessionCompletedTime={props.getSessionCompletedTime}
			/>
		);
	} else if (props.templateType === 'columns' || props.templateType === 'images') {
		return (
			<ColumnsTemplate
				scene={props.scene}
				children={props.children}
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				setChildIndex={props.setChildIndex}
				hideSceneName={props.hideSceneName}
				project={props.project}
				autoplay={props.autoplay}
				isFirstScene={isFirstScene()}
			/>
		);
	} else if (props.templateType === 'html') {
		return (
			<HtmlTemplate
				scene={props.scene}
				children={props.children}
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				setChildIndex={props.setChildIndex}
				hideSceneName={props.hideSceneName}
				project={props.project}
				autoplay={props.autoplay}
				isFirstScene={isFirstScene()}
			/>
		);
	} else if (props.templateType === 'video') {
		return (
			<VideoTemplate
				scene={props.scene}
				children={props.children}
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				setChildIndex={props.setChildIndex}
				hideSceneName={props.hideSceneName}
				project={props.project}
				showVideo={props.showVideo}
				autoplay={props.autoplay}
				showSceneQuestions={props.showSceneQuestions}
			/>
		);
	} else if (props.templateType === 'json') {
		return (
			<JsonTemplate
				scene={props.scene}
				children={props.children}
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				setChildIndex={props.setChildIndex}
				hideSceneName={props.hideSceneName}
				project={props.project}
				autoplay={props.autoplay}
				showSceneQuestions={props.showSceneQuestions}
			/>
		);
	} else if (props.templateType === 'freeText') {
		return (
			<FreeTextTemplate
				scene={props.scene}
				children={props.children}
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				setChildIndex={props.setChildIndex}
				hideSceneName={props.hideSceneName}
				project={props.project}
				autoplay={props.autoplay}
				showSceneQuestions={props.showSceneQuestions}
				getSessionCompletedTime={props.getSessionCompletedTime}
			/>
		);
	} else if (props.templateType === 'text_image'){
		return(
			<TextImageTemplate
				scene={props.scene}
				children={props.children}
				goTo={props.goTo}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				project={props.project}
				autoplay={props.autoplay}
				showSceneQuestions={props.showSceneQuestions}
				getSessionCompletedTime={props.getSessionCompletedTime}
			/>
		);
	} else {
		// if nothing else works render default TextTemplate
		return (
			<TextTemplate
				scene={props.scene}
				children={props.children}
				goTo={props.goTo}
				templateType={props.templateType}
				backButton={props.backButton}
				firstSceneId={props.firstSceneId}
				goBack={props.goBack}
				hideSceneName={props.hideSceneName}
				project={props.project}
				autoplay={props.autoplay}
				showSceneQuestions={props.showSceneQuestions}
				getSessionCompletedTime={props.getSessionCompletedTime}
			/>
		);
	}
}

export default Template;