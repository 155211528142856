import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from '../../utils/useTranslation';
import styles from './ColumnsTemplate.module.scss';
import getOptimizedImage from '../../utils/getOptimizedImage';
import { ELEMENTS } from '../../utils/elements';
import styled from 'styled-components';
import { CONSTANTS, LOTTIE_FILE_EXTENSIONS } from '../../utils/constants';
import { handleAnswerClick } from '../../utils/handleAnswerClick';
import LottiePlayer from '../Lottie/LottiePlayer';

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const ColumnAnswer = styled.div`
	:hover {
		box-shadow: 0 0 5px ${props => props.projectBgButtonColor};
		// border: 1px solid ${props => props.projectButtonColor};
	}
`;

/* Called by Template.js.
	 Renders the 'columns' and 'images' templates*/
function ColumnsTemplate(props) {
	const t = useTranslation(props.project?.language);

	//PROJECT COLORS
	const projectColor = props.project?.general?.color?.hex;
	const projectButtonColor2 = props.project?.general?.buttonColor2?.hex;
	const projectBgButtonColor = props.project?.general?.buttonBackgroundColor?.hex;
	const projectButtonColor = props.project?.general?.buttonColor?.hex;
	const isTemplateImages = props.scene.template === 'images';
	const imageTemplate = CONSTANTS.imageTemplate;

	const animationContainerRef = useRef();
	const { contextSafe } = useGSAP({scope: animationContainerRef});
	const initGsapAnimation = contextSafe(() => {
		gsap.from(`.${ELEMENTS.ANIMATION_ITEM}`, {opacity: 0, y: 30, stagger: 0.1});
	});

	useEffect(() => {
		initGsapAnimation();
	}, [props.scene._id]);


	function renderAnswers(answers, key) {
		return (
			<div className={styles.buttonsContainer}>
				{answers.map((answer, index) => {
					const answerTitle = answer.imageTitle ? ` [${answer.imageTitle}]` : '';
					const answerPrice = answer.price ? ` (${answer.price})` : '';
					let historyAnswer = answer.answer + answerTitle + answerPrice;

					let imageUrl = imageTemplate;
					if (answer.imageUrl) {
						imageUrl = answer.retrieveOrigSize ? answer.imageUrl : getOptimizedImage(answer.imageUrl, {width: 500, height: 500});
					}

					const isLottie = LOTTIE_FILE_EXTENSIONS.includes(imageUrl.split('.').pop());

					return (
						<div
							className={classNames(styles.buttonWrapper, {
								[styles.buttonWrapperSmall]: answers.length > 3
							}, ELEMENTS.ANIMATION_ITEM)}
							onClick={(e) => handleAnswerClick(e, props.goTo, answer, historyAnswer)}
							key={index}
						>
							<ColumnAnswer
								projectBgButtonColor={projectBgButtonColor}
								projectButtonColor={projectButtonColor}
								className={classNames(styles.answerColumn, ELEMENTS.SCENE_ANSWER_COLUMN, { [styles.imagesTemplate]: isTemplateImages })}
							>
								{isLottie ? <LottiePlayer src={imageUrl} />
									: <img
											src={imageUrl}
											// src={answer.imageUrl && answer.imageUrl.length > 0 ? getOptimizedImage(answer.imageUrl, {width: 500, height: 500}) : imageTemplate}
											alt={answer.imageDescription || 'column'}
											title={answer.imageDescription}
											className={classNames(styles.image, {
												[styles.imageSmall]: answers.length > 3,
												[styles.imagesTemplate]: isTemplateImages
											})}
										/>
								}

								{answer.price && (
									<span
										className={classNames(styles.priceBubble, {
											[styles.priceBubbleSmall]: answers.length > 3
										})}
										style={{ backgroundColor: projectBgButtonColor }}
									>
										<span
											className={classNames(styles.priceLabel, {
												[styles.priceLabelSmall]: answers.length > 3,
											})}
											style={{ color: projectButtonColor }}
										>
											{t.template.priceFrom}
										</span>
										<span
											className={classNames(styles.price, {
												[styles.priceSmall]: answers.length > 3,
											})}
											style={{ color: projectButtonColor }}
										>
											{answer.price}
										</span>
									</span>
								)}
								{answer.imageTitle && (
									<h4 className={classNames(styles.imageTitle, ELEMENTS.SCENE_IMAGE_TITLE)} style={{ color: projectColor }}>
										{answer.imageTitle}
									</h4>
								)}
								{answer.richText && <div className={classNames(styles.richText, ELEMENTS.SCENE_RICH_TEXT)} dangerouslySetInnerHTML={{ __html: answer.richText }} style={{ color: projectColor }}></div>}
								<span
									className={classNames(ELEMENTS.SCENE_ANSWER_LABEL, styles.answerLabel, { [styles.columnTemplate]: !isTemplateImages })}
									style={isTemplateImages ? { color: projectButtonColor2 } : { color: projectButtonColor, backgroundColor: projectBgButtonColor }}
								>
									{answer.answer}
								</span>
							</ColumnAnswer>
						</div>
					);
				})}
			</div>
		);
	}

	function groupAnswers(answers) {
		if (answers.length === 5) {
			return (
				<React.Fragment>
					{renderAnswers(answers.slice(0, 3), '1')}
					{renderAnswers(answers.slice(3, 5), '2')}
				</React.Fragment>
			);
		} else if (answers.length === 6) {
			return (
				<React.Fragment>
					{renderAnswers(answers.slice(0, 3), '1')}
					{renderAnswers(answers.slice(3, 6), '2')}
				</React.Fragment>
			);
		} else if (answers.length === 7) {
			return (
				<React.Fragment>
					{renderAnswers(answers.slice(0, 4), '1')}
					{renderAnswers(answers.slice(4, 7), '2')}
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					{renderAnswers(answers.slice(0, 4), '1')}
					{renderAnswers(answers.slice(4, 9), '2')}
				</React.Fragment>
			);
		}
	}

	return (
		<React.Fragment>
			<div
				ref={animationContainerRef}
				className={classNames(styles.template, '_dbw_columnsTemplate')}
				id={`scene-${props.scene._id}`}
				style={{
					backgroundColor: props.project?.general?.backgroundColor?.hex,
				}}
			>
				<div className={classNames(styles.templateRow)}>
					{!props.hideSceneName && (
						<div className={styles.titleWrapper}>
							<span className={styles.title}>{props.scene && props.scene.title}</span>
						</div>
					)}
					<h1
						className={classNames(styles.question, ELEMENTS.SCENE_TITLE, ELEMENTS.ANIMATION_ITEM, props.scene?.richText !== null && styles.withDescription)}
						style={{
							color: projectColor,
						}}
					>
						{props.scene && props.scene.question}{' '}
					</h1>
					{props.scene?.richText && isTemplateImages && (
						<div className={classNames(ELEMENTS.SCENE_DESCRIPTION, ELEMENTS.ANIMATION_ITEM, styles.description)} dangerouslySetInnerHTML={{ __html: props.scene.richText }} style={{ color: projectColor }}></div>
					)}
					{!isTemplateImages ? groupAnswers(props.scene?.answers) : renderAnswers(props.scene?.answers, '1')}
				</div>
				{props.children}
			</div>
		</React.Fragment>
	);
}

export default ColumnsTemplate;