import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from '../Template/Template.module.scss'; // TODO: extract needed css from here and place it in TextTemplate.module.scss
// import playCircle from '../../assets/icons/play-circle.svg';
import { ELEMENTS } from '../../utils/elements';
import Button from '../Button/Button';
import { handleAnswerClick } from '../../utils/handleAnswerClick';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const TextTemplate = (props) => {
	const projectColors = {
		BgColor: props.project?.general?.backgroundColor?.hex,
		TextColor: props.project?.general?.color?.hex,
		ButtonColor: props.project?.general?.buttonColor?.hex,
		ButtonBgColor: props.project?.general?.buttonBackgroundColor?.hex
	}

	const animationContainerRef = useRef();
	const { contextSafe } = useGSAP({scope: animationContainerRef});
	const initGsapAnimation = contextSafe(() => {
		gsap.from(`.${ELEMENTS.ANIMATION_ITEM}`, {opacity: 0, y: 30, stagger: 0.1});
	});

	useEffect(() => {
		initGsapAnimation();
	}, [props.scene._id]);

	return (
		<React.Fragment>
			<div id={`scene-${props.scene._id}`} // TODO: make this id appear only once in code for all the scene templates
				className={classNames(styles.template, styles._clickMaster)}
				style={{ backgroundColor: projectColors.BgColor }}
				ref={animationContainerRef}
			>
				<div className={styles.templateRow}>
					<h1
						className={classNames(ELEMENTS.SCENE_TITLE, ELEMENTS.ANIMATION_ITEM, styles.question)}
						style={{ color: projectColors.TextColor }}
					>
						{props.scene?.question}{' '}
					</h1>
					{props.scene?.richText && (
						<div
							className={classNames(ELEMENTS.SCENE_DESCRIPTION, ELEMENTS.ANIMATION_ITEM, styles.description)}
							dangerouslySetInnerHTML={{ __html: props.scene.richText }}
							style={{ color: projectColors.TextColor }}
						></div>
					)}
					<div className={styles.buttonsContainer} >
						{props.scene?.answers.map((answer, index) => (
							<div
								className={classNames(ELEMENTS.SCENE_ANSWER_LABEL, ELEMENTS.ANIMATION_ITEM, styles.buttonWrapper, {
									[styles.buttonWrapperSmall]: props.scene?.answers.length > 3,
								})}
								key={index}
							>
								<Button
									key={answer.answer + props.scene._id}
									label={answer.answer}
									secondary
									onClick={() => handleAnswerClick(null, props.goTo, answer, answer.answer)}
									style={{ color: projectColors.ButtonColor, backgroundColor: projectColors.BgColor }}
								/>
							</div>
						))}
					</div>
				</div>
				{props.children}
			</div>
		</React.Fragment>
	)
}

export default TextTemplate;