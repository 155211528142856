import React, { useEffect, useRef } from 'react';
import { DotLottie } from '@lottiefiles/dotlottie-web';
import styles from './Lottie.module.scss';

/**
 * Fit the canvas element to container (parent) element width
 * @param canvas
 * Ref URL: https://stackoverflow.com/a/10215724
 */
// function fitToContainer(canvas){
// 	// Make it visually fill the positioned parent
// 	canvas.style.width ='100%';
// 	canvas.style.height='100%';
// 	// ...then set the internal size to match
// 	canvas.width  = canvas.offsetWidth;
// 	canvas.height = canvas.offsetHeight;
// }

/**
 * Custom React Player that supports .json and .lottie types for both hosted and embeded clickmaster
 * @param src
 * @param aspectRatio
 * @returns {Element}
 * @constructor
 */
const LottiePlayer = ({src}) => {

	const canvasRef = useRef(null);

	useEffect(() => {
		const lottieAnimation = new DotLottie({
			autoplay: true,
			loop: true,
			canvas: canvasRef.current, // the dom element that will contain the animation
			src: src // the path to the animation json
			// src: "https://lottie.host/5f7f4690-6311-4279-82e4-38c2eab146ab/niPwIBUnGa.json" // the path to the animation json
		});

		return () => {
			// this prevents crash caused by bloated memory when we do not clean the components
			lottieAnimation.destroy();
		};
	}, [src]);

	return (
		<canvas className={styles.lottieCanvas} ref={canvasRef} />
	);
}

export default LottiePlayer;