import { combineReducers } from 'redux';
import modal from './modal';
import muteButton from './audioplayerMuteButton';
import jsonTemplate from './jsonTemplate'; 

const appReducer = combineReducers({
	modal,
	muteButton,
	jsonTemplate
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_USER') {
		localStorage.clear();
	}
	return appReducer(state, action);
};

export default rootReducer;
