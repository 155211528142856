import React from 'react';
import classNames from 'classnames';
import { ELEMENTS } from '../../utils/elements';
import styles from './Button.module.scss';

function Button(props) {
	return (
		<button
			onClick={props.onClick}
			disabled={props.disabled}
			className={classNames(
				styles.button,
				{
					[styles.primary]: props.primary,
					[styles.accent]: props.accent,
					[styles.secondary]: props.secondary,
					[styles.small]: props.small,
					[styles.disabled]: props.disabled
				},
				ELEMENTS.BUTTON
			)}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			type={props.type && props.type}
			style={props.style && props.style}
		>
			{props.icon && <img src={props.icon} alt={props?.imageDescription || 'buttonIcon'} title={props?.imageDescription || 'buttonIcon'} className={styles.icon} />}
			{props.label}
		</button>
	);
}

export default Button;