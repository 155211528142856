import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers/index';

import Widget from "./components/Widget/Widget";
import Modal from "./components/Modal/Modal";

export const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(thunk),
		...(window.__REDUX_DEVTOOLS_EXTENSION__ ? [window.__REDUX_DEVTOOLS_EXTENSION__()] : [])
		//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	)
);
const widgetDivs = document.querySelectorAll('.clickmaster-widget');

widgetDivs.forEach(div => {
	const root = createRoot(div);
	root.render(
		<React.StrictMode>
		<Provider store={store}>
			<Widget embeddedProjectID={div.dataset.project} sendToEmail={div.dataset.sendtoemail}/>
			<Modal />
		</Provider>
	</React.StrictMode>
	);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();