import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMuteButton } from '../../actions/audioplayerMuteButtonActions';
import styles from './AudioPlayer.module.scss';
import ReactHowler from 'react-howler';
import { ReactComponent as MutedSpeakerIcon} from '../../assets/icons/mutedSpeaker.svg';
import { ReactComponent as SpeakerIcon } from '../../assets/icons/speaker.svg';

const AudioPlayer = (props) => {
	const dispatch = useDispatch();
	const [showPlayButton, setShowPlayButton] = useState(props.isFirstScene);
	const isMuted = useSelector((state) => state.muteButton.isMuted);
	const [muted, setMuted] = useState(isMuted);
	const projectButtonBgColor = props?.project?.general?.buttonBackgroundColor?.hex

	useEffect(() => {
		setShowPlayButton(props.isFirstScene);
	}, [props.isFirstScene]);

	return (
		<div>
			<div>
				{showPlayButton && (
					<figure className={styles.playButton}>
						<button onClick={() => setShowPlayButton(false)} name="play"></button>
					</figure>
				)}
			</div>
			{!showPlayButton && (
				<div className={styles.muteContainer} onClick={() => {
					setMuted(!muted);
					dispatch(setMuteButton(!isMuted));
				}}>
					{!muted && <SpeakerIcon stroke={projectButtonBgColor} fill={projectButtonBgColor} />}
					{muted && <MutedSpeakerIcon fill={projectButtonBgColor} /> }
				</div>)
			}
			<ReactHowler src={props.audioUrl} playing={!muted && !showPlayButton} mute={muted} />
		</div>
	);
};

export default AudioPlayer;