import React from 'react';
import classNames from 'classnames';
import styles from './HtmlTemplate.module.scss';
import { ELEMENTS } from '../../utils/elements';
import Button from '../Button/Button';
import { handleAnswerClick } from '../../utils/handleAnswerClick';

const DEFAULT_HTML_IMAGE_URL = 'https://res.cloudinary.com/five-digital-gmbh/image/upload/v1660210182/digital_berater/HTML-DefaultImage_kqvvg5.png'

function HtmlTemplate(props) {

	//PROJECT COLORS
	const projectColor = props?.project?.general?.color?.hex;
	const projectBgButtonColor = props?.project?.general?.buttonBackgroundColor?.hex;
	const projectButtonColor = props?.project?.general?.buttonColor?.hex;

	function renderAnswers(answers) {
		return (
			<div className={classNames(styles.buttonsContainer)}>
				{answers.map((answer, index) => (
					<div className={styles.buttonWrapper} key={index}>
						<Button
							key={answer.answer + props.scene._id}
							label={answer.answer}
							secondary
							onClick={(e) => handleAnswerClick(e, props.goTo, answer, answer.answer)}
							style={{
								minWidth: props.scene?.answers?.length === 4 && '80px',
								color: projectButtonColor,
								backgroundColor: projectBgButtonColor,
								background: projectBgButtonColor,
							}}
						/>
					</div>
				))}
			</div>
		);
	}

	return (
		<React.Fragment>
			<div
				className={classNames(styles.template, '_dbw_HtmlTemplate')}
				id={`scene-${props.scene._id}`}
				style={{
					backgroundColor: props?.project?.general?.backgroundColor?.hex,
				}}
			>
				<div className={classNames(styles.templateRow)}>
					{!props.hideSceneName && (
						<div className={styles.titleWrapper}>
							<span className={styles.title}>{props.scene && props.scene.title}</span>
						</div>
					)}
					<h1
						className={classNames(styles.question, ELEMENTS.SCENE_TITLE, props.scene?.richText !== null && styles.withDescription)}
						style={{
							color: projectColor,
						}}
					>
						{props.scene && props.scene.question}{' '}
					</h1>

					<div className={styles.customHtml}>
          {props.scene.customHtml !== '' ? <div dangerouslySetInnerHTML={{__html: props.scene.customHtml}} />
						: <img className={styles.defaultHtmlImg} src={DEFAULT_HTML_IMAGE_URL} alt="default html" />}
					</div>

					{props.scene?.answers && renderAnswers(props.scene.answers)}
				</div>
				{props.children}
			</div>
		</React.Fragment>
	);
}

export default HtmlTemplate;