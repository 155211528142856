import de from '../translations/de.json';
import en from '../translations/en.json';
import es from '../translations/es.json';
import hr from '../translations/hr.json';
import { FALLBACK_LANGUAGE } from './constants';

const t = {
	DE: de,
	EN: en,
	ES: es,
	HR: hr,
};

export const useTranslation = (language) => {
	if (!language) language = FALLBACK_LANGUAGE; // 'EN' or 'DE, for example
	return t[language];
};
