// return object with properties and values corresponding to UTM key-value pairs
export const getUTMObject = (url) => {
  const urlParams = new URLSearchParams(url);
  const entries = urlParams.entries();
  const urlEntries = {};

  for (const entry of entries) {
    const data = {
      [entry[0]]: entry[1],
    };
    Object.assign(urlEntries, data);
  }
  return urlEntries
}

		