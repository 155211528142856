import React from "react";
import Button from '../Button/Button';
import styles from './PopupModal.module.scss';

function PopupModal(props) {
	return (
		<div className={styles.popup}>
			<h1 className={styles.title}>{props.modalProps.title}</h1>
			{props.modalProps.subtitle && <h2 className={styles.subtitle}>{props.modalProps.subtitle}</h2>}
			<div className={styles.buttonsContainer}>
				<div className={styles.buttonWrapper}>
					{props.modalProps.buttonSecondary && (
						<Button
							label={props.modalProps.buttonSecondary.label}
							onClick={() => props.modalProps.buttonSecondary.action()}
							secondary
							small
						/>
					)}
				</div>
				<div className={styles.buttonWrapper}>
					{props.modalProps.buttonPrimary && (
						<Button
							label={props.modalProps.buttonPrimary.label}
							onClick={() => props.modalProps.buttonPrimary.action()}
							primary
							small
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default PopupModal;