import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import PopupModal from '../../components/PopupModal/PopupModal';
import { hideModal } from '../../actions/modalActions';

import styles from './Modal.module.scss';

const MODAL_COMPONENTS = {
	POPUP_MODAL: PopupModal,
};

function Modal() {
	const modal = useSelector((state) => state.modal);
	const dispatch = useDispatch();

	if (!modal.modalType) {
		return null;
	}
	const SpecificModal = MODAL_COMPONENTS[modal.modalType];

	return (
		<div className={styles.overlay}>
			<div className={styles.modal}>
				<SpecificModal hideModal={hideModal} dispatch={dispatch} modalProps={modal.modalProps} />
			</div>
		</div>
	);
}

export default Modal;
