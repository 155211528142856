// TODO: change this to a currently existing ID
export const FALLBACK_PROJECT_ID = '61e809782e75eaa8fa170d3b'

// This is for the demo project for developer testing on the Croative.co landing page
export const DEMO_PROJECT_ID = '64b10218ea7b3f1234d2c5de'

export const FALLBACK_LANGUAGE = 'EN'

export const CONSTANTS = Object.freeze({
	imageTemplate: 'https://res.cloudinary.com/five-digital-gmbh/image/upload/v1655816905/Platzhalter-Bild_xfh1f6.jpg',
});

export const DATE_FORMAT = 'DD MMM YYYY';

export const LOTTIE_FILE_EXTENSIONS = ['json', 'lottie'];